<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-4">
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 TMG</p>
      <strong>Anschrift</strong>
      <p class="mb-0">Bauchgefühle Gießen e.V.</p>
      <p class="mb-0">Edlef-Köppen-Weg 2</p>
      <p>35394 Gießen</p>
      <strong>Vorsitzende</strong>
      <p>Veronika Lehner</p>
      <strong>Kontakt</strong>
      <p>
        E-Mail:
        <a href="mailto:mail@bauchgefuehle-giessen.de"
          >mail@bauchgefuehle-giessen.de</a
        >
      </p>
      <strong>Registereintrag</strong>
      <p class="mb-0">Eintragung im Amtsgericht Gießen</p>
      <p>Registernummer: VR 5055</p>
    </v-sheet>
    <v-sheet class="pa-8 mb-4">
      <h2>Haftungsausschluss</h2>
      <strong>Haftung für Inhalte</strong>
      <p align="justify">
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <strong>Haftung für Links</strong>
      <p align="justify">
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <strong>Urheberrecht</strong>
      <p align="justify">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
      <strong>Datenschutz</strong>
      <p align="justify">
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
        die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
        dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen
        der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
        Übersendung von nicht ausdrücklich angeforderter Werbung und
        Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-Mails, vor.
      </p>
    </v-sheet>
    <v-sheet class="pa-8 mb-4">
      <h2>Datenschutzerklärung</h2>
      <strong>Datenschutz</strong>
      <p align="justify">
        Wir haben diese Datenschutzerklärung (Fassung 14.09.2020) verfasst, um
        Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679
        zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden
        und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
        haben.
      </p>
      <p align="justify">
        Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
        technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
        wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
      </p>
      <strong>Automatische Datenspeicherung</strong>
      <p align="justify">
        Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
        automatisch erstellt und gespeichert, so auch auf dieser Webseite.
      </p>
      <p align="justify" class="mb-1">
        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
        Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch
        Daten wie
      </p>
      <ul class="mb-2">
        <li>
          die Adresse (URL) der aufgerufenen Webseite
        </li>
        <li>
          Browser und Browserversion
        </li>
        <li>
          das verwendete Betriebssystem
        </li>
        <li>
          die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
        </li>
        <li>
          den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen
          wird
        </li>
        <li>
          Datum und Uhrzeit
        </li>
      </ul>
      <p align="justify">in Dateien (Webserver-Logfiles).</p>
      <p align="justify">
        In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
        danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können
        jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten eingesehen werden.
      </p>
      <strong>Speicherung persönlicher Daten</strong>
      <p align="justify">
        Persönliche Daten, die Sie uns auf dieser Website elektronisch
        übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere
        persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
        Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der
        IP-Adresse nur zum jeweils angegebenen Zweck verwendet.
      </p>
      <p align="justify">
        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
        jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
        Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
        Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
        weiter, können jedoch nicht ausschließen, dass diese Daten beim
        Vorliegen von rechtswidrigem Verhalten eingesehen werden.
      </p>
      <p align="justify">
        Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits
        dieser Webseite – können wir keine sichere Übertragung und den Schutz
        Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals
        unverschlüsselt per E-Mail zu übermitteln.
      </p>
      <p align="justify">
        Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO
        (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung
        zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können
        diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht
        aus, Sie finden unsere Kontaktdaten im Impressum.
      </p>
      <strong>Rechte laut Datenschutzgrundverordnung</strong>
      <p align="justify" class="mb-1">
        Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende
        Rechte zu:
      </p>
      <ul class="mb-3">
        <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
        <li>
          Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        </li>
        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
        <li>
          Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
          der Berichtigung oder Löschung personenbezogener Daten oder der
          Einschränkung der Verarbeitung (Artikel 19 DSGVO)
        </li>
        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
        <li>
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
          unterworfen zu werden (Artikel 22 DSGVO)
        </li>
      </ul>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in einer Weise verletzt worden sind, können Sie sich an die
        Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
        (BfDI) wenden.
      </p>
      <strong>Auswertung des Besucherverhaltens</strong>
      <p align="justify">
        In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
        und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung
        der gesammelten Daten erfolgt in der Regel anonym und wir können von
        Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
      </p>
      <p>
        Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
        widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
      </p>
      <strong>TLS-Verschlüsselung mit https</strong>
      <p align="justify">
        Wir verwenden https um Daten abhörsicher im Internet zu übertragen
        (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch
        den Einsatz von TLS (Transport Layer Security), einem
        Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
        können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
        die Benutzung dieser Absicherung der Datenübertragung am kleinen
        Schloßsymbol links oben im Browser und der Verwendung des Schemas https
        (anstatt http) als Teil unserer Internetadresse.
      </p>
      <strong>Google Fonts Datenschutzerklärung</strong>
      <p align="justify">
        Auf unserer Website verwenden wir Google Fonts. Das sind die
        “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist
        das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
        Dublin 4, Irland) für alle Google-Dienste verantwortlich.
      </p>
      <p align="justify">
        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
        anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine
        Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
        Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com
        und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach
        CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten.
        Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass
        Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an
        Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading
        Style Sheets) und der verwendeten Schriftarten und speichert diese Daten
        sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im
        Detail ansehen.
      </p>
      <strong>Was sind Google Fonts?</strong>
      <p align="justify">
        Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800
        Schriftarten, die Google seinen Nutzern kostenlos zu Verfügung stellt.
      </p>
      <p align="justify">
        Viele dieser Schriftarten sind unter der SIL Open Font License
        veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht
        wurden. Beides sind freie Software-Lizenzen.
      </p>
      <strong>Warum verwenden wir Google Fonts auf unserer Webseite?</strong>
      <p align="justify">
        Mit Google Fonts können wir auf unserer eigenen Webseite Schriften
        nutzen, doch müssen sie nicht auf unseren eigenen Server hochladen.
        Google Fonts ist ein wichtiger Baustein, um die Qualität unserer
        Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das
        Web optimiert und dies spart Datenvolumen und ist speziell für die
        Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere
        Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle
        Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
        Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen
        Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern
        führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten
        optisch verzerren. Dank des schnellen Content Delivery Networks (CDN)
        gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google
        Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox,
        Apple Safari, Opera) und funktioniert zuverlässig auf den meisten
        modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS
        4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit
        wir unser gesamtes Online-Service so schön und einheitlich wie möglich
        darstellen können.
      </p>
      <strong>Welche Daten werden von Google gespeichert?</strong>
      <p align="justify">
        Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
        Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an
        die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw.
        Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde
        entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten
        auf das zu reduzieren, was für eine ordentliche Bereitstellung von
        Schriften nötig ist. API steht übrigens für „Application Programming
        Interface“ und dient unter anderem als Datenübermittler im
        Softwarebereich.
      </p>
      <p align="justify">
        Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und
        ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google
        feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
        veröffentlicht Google auf internen Analyseseiten, wie beispielsweise
        Google Analytics. Zudem verwendet Google auch Daten des eigenen
        Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften
        verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
        veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice
        BigQuery, um große Datenmengen untersuchen und bewegen zu können.
      </p>
      <p align="justify">
        Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage
        auch Informationen wie Spracheinstellungen, IP-Adresse, Version des
        Browsers, Bildschirmauflösung des Browsers und Name des Browsers
        automatisch an die Google-Server übertragen werden. Ob diese Daten auch
        gespeichert werden, ist nicht klar feststellbar bzw. wird von Google
        nicht eindeutig kommuniziert.
      </p>
      <strong>Wie lange und wo werden die Daten gespeichert?</strong>
      <p align="justify">
        Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
        Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
        ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu
        nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und
        schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
      </p>
      <p align="justify">
        Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt
        damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern.
        Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
        werden sie nach dem ersten Besuch zwischengespeichert und erscheinen
        sofort auf allen anderen später besuchten Webseiten wieder. Manchmal
        aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die
        Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
      </p>
      <strong
        >Wie kann ich meine Daten löschen bzw. die Datenspeicherung
        verhindern?</strong
      >
      <p align="justify">
        Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können
        nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf
        automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu
        können, müssen Sie den Google-Support auf
        <a href="https://support.google.com/?hl=de&tid=311212339"
          >https://support.google.com/?hl=de&tid=311212339</a
        >
        kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn
        Sie unsere Seite nicht besuchen.
      </p>
      <p align="justify">
        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten
        Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer
        an Schriftarten zugreifen und so das Optimum für unsere Webseite
        rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
        <a href="https://developers.google.com/fonts/faq?tid=311212339"
          >https://developers.google.com/fonts/faq?tid=311212339</a
        >. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein,
        doch wirklich detaillierte Informationen über Datenspeicherung sind
        nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
        Informationen über gespeicherten Daten zu bekommen.
      </p>
      <p align="justify">
        Welche Daten grundsätzlich von Google erfasst werden und wofür diese
        Daten verwendet werden, können Sie auch auf
        https://www.google.com/intl/de/policies/privacy/ nachlesen.
      </p>
      <p align="justify">
        Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in
        Kooperation mit hashtagmann.de
      </p>
    </v-sheet>
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "impress",
  components: {
    Breadcrumbs
  },
  data: () => ({}),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("impress"),
          disabled: true
        }
      ];
    }
  }
};
</script>
